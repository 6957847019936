import { Separator, useBreakpointValue } from '@chakra-ui/react';
import { SetStateAction, useState } from 'react';
import { SharepointFileSelectionMap, StorageFileSelectionMap } from 'common-ts';

import AccessControl from '../../../components/AccessControl.js';
import CollectionNavigator from './CollectionNavigator.js';
import { ErrorBoundary } from '@sentry/react';
import ListItem from '../../../components/ListItem.js';
import SearchBar from '@/components/SearchBar.js';
import SharepointLogo from '../../../components/icons/SharepointLogo.js';
import SharepointSiteNavigator from './SharepointSiteNavigator.js';
import SharepointWaitlistInfo from '../../../components/sharepoint/SharepointWaitlistInfo.js';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '../../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';

type Source = 'OWN_COLLECTIONS' | 'SHARED' | 'WORKSPACE' | 'SHAREPOINT';

type FileSelectorProps = {
  className?: string;
  selectedSharepointFileMap: SharepointFileSelectionMap;
  selectedStorageFileMap: StorageFileSelectionMap;
  onUpdateSelectedSharepointFileMap: (
    updateValue: SetStateAction<SharepointFileSelectionMap>
  ) => void;
  onUpdateSelectedStorageFileMap: (
    updateValue: SetStateAction<StorageFileSelectionMap>
  ) => void;
};

function FileSelector({
  className,
  selectedStorageFileMap,
  selectedSharepointFileMap,
  onUpdateSelectedSharepointFileMap,
  onUpdateSelectedStorageFileMap,
}: FileSelectorProps) {
  const { t } = useTranslation();
  const extendedBuckets = useBoundStore((state) => state.extendedBuckets);
  const featureFlags = useBoundStore((state) => state.featureFlags);

  const [searchTerm, setSearchTerm] = useState('');
  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [selectedSource, setSelectedSource] = useState<Source | undefined>(
    isMobile ? undefined : 'OWN_COLLECTIONS'
  );
  const [selectedCollection, setSelectedCollection] = useState<
    | {
        name: string;
        id: string;
      }
    | undefined
  >(undefined);

  function handleChangeSelectedSource(source: Source | undefined) {
    setSelectedSource(source);
    setSelectedCollection(undefined);
  }

  return (
    <div
      className={twMerge(
        'flex h-full w-full flex-col gap-4 overflow-x-auto',
        className
      )}
    >
      <div className="flex items-center justify-between px-4 pt-4">
        <div className="text-lg font-bold">{t('chat.fileSelector.title')}</div>
        <SearchBar
          onSearchTermChange={setSearchTerm}
          className={`${!selectedSource || selectedSource === 'SHAREPOINT' || !selectedCollection ? 'invisible' : ''}`}
        />
      </div>
      <div className="flex-grow md:flex md:h-[515px]">
        {/* ---------------------------------------------------------------- Source selection ---------------------------------------------------------------- */}
        <div
          className={`border-maia-border flex min-w-72 flex-col gap-2 border-b border-r border-t p-4 ${selectedSource ? 'hidden md:flex' : ''}`}
        >
          {/* ------------------------------------------------ MAIA Data Manager ------------------------------------------------ */}
          <div className="font-medium">
            {t('chat.fileSelector.collectionsTitle')}
          </div>
          <ListItem
            className="cursor-pointer select-none"
            selected={selectedSource === 'OWN_COLLECTIONS'}
            variant="left"
            domElementProps={{
              onClick: () => {
                handleChangeSelectedSource('OWN_COLLECTIONS');
              },
            }}
          >
            {t('chat.fileSelector.yourCollections')}
          </ListItem>
          <ListItem
            className="cursor-pointer select-none"
            selected={selectedSource === 'SHARED'}
            variant="left"
            domElementProps={{
              onClick: () => {
                handleChangeSelectedSource('SHARED');
              },
            }}
          >
            {t('chat.fileSelector.sharedWithYou')}
          </ListItem>
          <ListItem
            className="cursor-pointer select-none"
            selected={selectedSource === 'WORKSPACE'}
            variant="left"
            domElementProps={{
              onClick: () => {
                handleChangeSelectedSource('WORKSPACE');
              },
            }}
          >
            {t('chat.fileSelector.workspaceCollections')}
          </ListItem>
          {/* ------------------------------------------------ Integrations ------------------------------------------------ */}
          <AccessControl
            allowedLicenses={['ENTERPRISE', 'PROFESSIONAL', 'BASIC']}
          >
            <Separator />
            <div className="font-medium">
              {t('chat.fileSelector.integrationsTitle')}
            </div>
            <ListItem
              className="cursor-pointer select-none"
              selected={selectedSource === 'SHAREPOINT'}
              variant="left"
              domElementProps={{
                onClick: () => {
                  handleChangeSelectedSource('SHAREPOINT');
                },
              }}
            >
              <div className="flex items-center gap-2">
                <SharepointLogo />
                <div>{t('integrationSettings.sharepoint.title')}</div>
              </div>
            </ListItem>
          </AccessControl>
        </div>
        <ErrorBoundary
          fallback={
            <div className="p-4">
              {t('chat.fileSelector.errorBoundaryInfo')}
            </div>
          }
        >
          <div
            className={`flex h-full flex-grow ${!selectedSource ? 'invisible md:visible' : ''}`}
          >
            {/* ---------------------------------------------------------------- Your collections ---------------------------------------------------------------- */}
            {selectedSource === 'OWN_COLLECTIONS' ? (
              <CollectionNavigator
                selectedCollection={selectedCollection}
                onCollectionSelect={setSelectedCollection}
                searchTerm={searchTerm}
                collections={extendedBuckets.filter(
                  (bucket) =>
                    !bucket.is_shared_to_user && !bucket.is_public_for_workspace
                )}
                onUpdateSelectedFileMap={onUpdateSelectedStorageFileMap}
                selectedFileMap={selectedStorageFileMap}
                onBackClick={() => handleChangeSelectedSource(undefined)}
              />
            ) : /* ---------------------------------------------------------------- Shared with you ---------------------------------------------------------------- */
            selectedSource === 'SHARED' ? (
              <CollectionNavigator
                selectedCollection={selectedCollection}
                onCollectionSelect={setSelectedCollection}
                searchTerm={searchTerm}
                collections={extendedBuckets.filter(
                  (bucket) =>
                    bucket.is_shared_to_user && !bucket.is_public_for_workspace
                )}
                onUpdateSelectedFileMap={onUpdateSelectedStorageFileMap}
                selectedFileMap={selectedStorageFileMap}
                onBackClick={() => handleChangeSelectedSource(undefined)}
              />
            ) : /* ---------------------------------------------------------------- Workspace ---------------------------------------------------------------- */
            selectedSource === 'WORKSPACE' ? (
              <CollectionNavigator
                selectedCollection={selectedCollection}
                onCollectionSelect={setSelectedCollection}
                searchTerm={searchTerm}
                collections={extendedBuckets.filter(
                  (bucket) => bucket.is_public_for_workspace
                )}
                onUpdateSelectedFileMap={onUpdateSelectedStorageFileMap}
                selectedFileMap={selectedStorageFileMap}
                onBackClick={() => handleChangeSelectedSource(undefined)}
              />
            ) : selectedSource === 'SHAREPOINT' ? (
              /* ---------------------------------------------------------------- Sharepoint ---------------------------------------------------------------- */
              featureFlags?.sharepoint ? (
                <SharepointSiteNavigator
                  className="h-full"
                  onBackClick={() => {
                    handleChangeSelectedSource(undefined);
                  }}
                  selectedFileMap={selectedSharepointFileMap}
                  onUpdateSelectedFileMap={onUpdateSelectedSharepointFileMap}
                />
              ) : (
                <SharepointWaitlistInfo className="border-maia-border border-b border-t p-4" />
              )
            ) : (
              <div className="border-maia-border flex-grow border-b border-t" />
            )}
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default FileSelector;
