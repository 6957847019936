import { Button, ButtonProps } from '../../components/ui/button.js';

import FileUploadModal from './FileUploadModal.js';
import MaiaAlertDialogWithUpgradeButton from '../../components/MaiaAlertDialogWithUpgradeButton.js';
import { TempFileObject } from '../chat/fileSelector/useStorageSelectionStateHandlers.js';
import { Tooltip } from '../../components/ui/tooltip.js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons';

type FileUploadButtonProps = {
  uploadPath: string | undefined;
  isDisabled?: boolean;
  buttonProps?: ButtonProps;
  collectionId: string;
  usedStorageMb: number;
  storageLimitMb: number;
  existingFileNames: string[];
  onFilesStartedUploading?: (files: TempFileObject[]) => void;
  onFilesFinishedUploading?: (files: TempFileObject[]) => void;
};

function FileUploadButton({
  uploadPath,
  isDisabled,
  buttonProps,
  collectionId,
  usedStorageMb,
  storageLimitMb,
  existingFileNames,
  onFilesStartedUploading,
  onFilesFinishedUploading,
}: FileUploadButtonProps) {
  const { t } = useTranslation();

  const [upgradeModalOpen, setUpgradeModalOpen] = useState<
    'storage' | 'multiUpload' | undefined
  >(undefined);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  return (
    <>
      <Tooltip
        content={t('fileManagerPanel.disabledReason')}
        openDelay={500}
        disabled={!isDisabled}
      >
        <Button
          className="h-8"
          disabled={isDisabled}
          colorPalette="maia-purple"
          onClick={() => setUploadModalOpen(true)}
          {...buttonProps}
        >
          {t('fileManagerPanel.uploadFileButton')}
          <FontAwesomeIcon icon={faArrowUpFromBracket} />
        </Button>
      </Tooltip>
      <MaiaAlertDialogWithUpgradeButton
        isOpen={!!upgradeModalOpen}
        onClose={() => setUpgradeModalOpen(undefined)}
        AlertDialogHeaderText={
          upgradeModalOpen === 'multiUpload'
            ? t('fileManagerPanel.multiUploadAttempt')
            : t('fileManagerPanel.storageLimitReached')
        }
        AlertDialogBodyText={
          upgradeModalOpen === 'multiUpload'
            ? t('fileManagerPanel.multiUploadAttemptBody')
            : t('fileManagerPanel.storageLimitReachedBody')
        }
      />
      <FileUploadModal
        isOpen={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        uploadPath={uploadPath}
        collectionId={collectionId}
        usedStorageMb={usedStorageMb}
        storageLimitMb={storageLimitMb}
        existingFileNames={existingFileNames}
        onFilesStartedUploading={(files) => {
          setUploadModalOpen(false);
          onFilesStartedUploading?.(files);
        }}
        onFilesFinishedUploading={onFilesFinishedUploading}
        onFreeStorageLimitReached={() => setUpgradeModalOpen('storage')}
        onFreeUserMultiFileAttempt={() => setUpgradeModalOpen('multiUpload')}
      />
    </>
  );
}

export default FileUploadButton;
