import {
  FocusEventHandler,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IconButton, Separator, chakra } from '@chakra-ui/react';
import {
  SharepointFileSelectionMap,
  StorageFileSelectionMap,
  decodeName,
} from 'common-ts';
import {
  faCabinetFiling,
  faFolderMinus,
  faFolderOpen,
  faPaperPlane,
} from '@fortawesome/pro-regular-svg-icons';

import AutoResize from 'react-textarea-autosize';
import { Button } from '../../components/ui/button.js';
import { ChangeLlm } from '../../components/ChangeLlm.js';
import FileSelector from './fileSelector/FileSelector.js';
import { FileSelectorModal } from './fileSelector/FileSelectorModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PromptLibraryModal from './promptLibrary/PromptLibraryModal';
import SharepointLogo from '../../components/icons/SharepointLogo';
import { Tag } from '../../components/ui/tag.js';
import { Tooltip } from '../../components/ui/tooltip.js';
import { useTranslation } from 'react-i18next';

type ChatInputProps = {
  answerOnTheWay: boolean;
  inputValue: string;
  collectionNames: Map<string, string>;
  showSelectFilesWaring: boolean;
  selectedSharepointFileMap: SharepointFileSelectionMap;
  selectedStorageFileMap: StorageFileSelectionMap;
  onEsc: () => void;
  onSend: () => void;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  onInputValueChange: (value: string) => void;
  onClearAllFiles: () => void;
  onUpdateSelectedSharepointFileMap: (
    updateValue: SetStateAction<SharepointFileSelectionMap>
  ) => void;
  onUpdateSelectedStorageFileMap: (
    updateValue: SetStateAction<StorageFileSelectionMap>
  ) => void;
};

const StyledAutoResize = chakra(AutoResize);

function ChatInput({
  answerOnTheWay,
  inputValue,
  collectionNames,
  showSelectFilesWaring,
  selectedStorageFileMap,
  selectedSharepointFileMap,
  onEsc,
  onSend,
  onBlur,
  onFocus,
  onInputValueChange,
  onClearAllFiles,
  onUpdateSelectedStorageFileMap,
  onUpdateSelectedSharepointFileMap,
}: ChatInputProps) {
  const { t } = useTranslation();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isFileSelectorModalOpen, setIsFileSelectorModalOpen] = useState(false);
  const [isPromptLibraryModalOpen, setIsPromptLibraryModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        textAreaRef.current?.focus();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const textArea = useMemo(() => {
    return (
      <StyledAutoResize
        ref={textAreaRef}
        maxLength={16384}
        value={inputValue}
        maxRows={8}
        minH="initial"
        resize="none"
        overflow="hidden"
        lineHeight="inherit"
        className="text-maia-text-dark h-full w-full self-center border-none text-sm font-medium outline-none"
        placeholder={t('chat.messagePlaceholder')}
        onChange={(e) => {
          onInputValueChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (e.shiftKey) {
              return;
            }
            if (inputValue.length > 0 && !answerOnTheWay) {
              onSend();
            }
            e.preventDefault();
          } else if (e.key === 'Escape') {
            onEsc();
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus
      />
    );
  }, [inputValue, onSend]);

  const fileSelector = useMemo(() => {
    return (
      <FileSelector
        onUpdateSelectedStorageFileMap={onUpdateSelectedStorageFileMap}
        selectedStorageFileMap={selectedStorageFileMap}
        selectedSharepointFileMap={selectedSharepointFileMap}
        onUpdateSelectedSharepointFileMap={onUpdateSelectedSharepointFileMap}
      />
    );
  }, [selectedStorageFileMap, selectedSharepointFileMap]);

  const selectedFileList = useMemo(() => {
    const selectedCollections: { id: string; name: string }[] = [];

    Object.entries(selectedStorageFileMap).forEach(
      ([collectionId, selectionInfo]) => {
        if (selectionInfo.selected || selectionInfo.selected === null) {
          selectedCollections.push({
            id: collectionId,
            name: selectionInfo.name,
          });
        }
      }
    );

    const selectedSharepointSites = Object.entries(
      selectedSharepointFileMap
    ).reduce(
      (selectedSites, [siteId, selectionInfo]) => {
        if (selectionInfo.selected || selectionInfo.selected === null) {
          selectedSites.push({ id: siteId, name: selectionInfo.name });
        }
        return selectedSites;
      },
      [] as { id: string; name: string }[]
    );

    const removeAllButton = (
      <Button
        display="flex"
        alignItems="center"
        className="text-maia-gray-900 items-center justify-between px-0 pr-2 font-medium"
        key="remove-all"
        variant="plain"
        onClick={() => {
          if (onClearAllFiles) {
            onClearAllFiles();
          }
        }}
      >
        <FontAwesomeIcon icon={faFolderMinus} className="text-maia-gray-900" />
        {t('chat.removeAllCollections')}
      </Button>
    );

    return selectedCollections.length + selectedSharepointSites.length > 0 ? (
      <>
        <Separator />
        <div className="flex w-full flex-wrap items-center py-2">
          {removeAllButton}
          {selectedCollections.map((collection) => (
            <div key={collection.id}>
              <Tag
                id={collection.id}
                className="mr-2 flex-shrink-0"
                variant="subtle"
                size={'md'}
                rounded={'2'}
                colorPalette="maia-gray"
                onClick={() => {
                  setIsFileSelectorModalOpen(true);
                }}
                style={{ cursor: 'pointer' }}
              >
                {`${decodeName(collection.name)}`}
              </Tag>
            </div>
          ))}
          {selectedSharepointSites.map((site) => {
            return (
              <div key={site.id}>
                <Tag
                  id={site.id}
                  className="mr-2 flex-shrink-0 truncate"
                  variant="subtle"
                  rounded={'2'}
                  colorPalette="maia-gray"
                  onClick={() => {
                    setIsFileSelectorModalOpen(true);
                  }}
                  style={{ cursor: 'pointer' }}
                  startElement={<SharepointLogo />}
                >
                  {site.name}
                </Tag>
              </div>
            );
          })}
        </div>
      </>
    ) : null;
  }, [selectedStorageFileMap, collectionNames, selectedSharepointFileMap]);

  const sendButton = useMemo(
    () => (
      <IconButton
        aria-label="send"
        size="xs"
        colorPalette="maia-accent"
        disabled={inputValue.length === 0 || answerOnTheWay}
        onClick={() => {
          onSend();
        }}
      >
        <FontAwesomeIcon icon={faPaperPlane} className="text-white" />
      </IconButton>
    ),
    [inputValue, answerOnTheWay, onSend]
  );

  const dataButton = useMemo(
    () => (
      <Tooltip
        content={t('chat.noFilesSelectedInfo')}
        open={showSelectFilesWaring}
        disabled
        showArrow
      >
        <Button
          display="flex"
          alignItems="center"
          className="text-maia-text-dark px-0 font-medium"
          aria-label="select-data"
          variant="plain"
          onClick={() => {
            setIsFileSelectorModalOpen(true);
          }}
        >
          <FontAwesomeIcon
            icon={faFolderOpen}
            className="text-maia-text-dark"
          />
          {t('chat.fileSelector.button')}
        </Button>
      </Tooltip>
    ),
    [inputValue, answerOnTheWay, onSend]
  );

  const promptButton = useMemo(
    () => (
      <Button
        display="flex"
        alignItems="center"
        className="text-maia-text-dark px-0 font-medium"
        aria-label="select-prompt"
        variant="plain"
        onClick={() => {
          setIsPromptLibraryModalOpen(true);
        }}
      >
        <FontAwesomeIcon
          icon={faCabinetFiling}
          className="text-maia-text-dark"
        />
        Prompt
      </Button>
    ),
    [inputValue, answerOnTheWay, onSend]
  );

  // const helpButton = useMemo(
  //   () => (
  //     <IconButton
  //       className=""
  //       icon={
  //         <FontAwesomeIcon
  //           icon={faQuestionCircle}
  //           className="text-maia-text-dark"
  //         />
  //       }
  //       aria-label="send"
  //       variant="unstyled"
  //       onClick={() => {
  //         // onSend();
  //       }}
  //     />
  //   ),
  //   [inputValue, answerOnTheWay, onSend]
  // );

  const modelSelect = useMemo(() => <ChangeLlm withExperimentalLlm />, []);

  function onClosePromptLibraryModal() {
    setIsPromptLibraryModalOpen(false);
  }

  function handleUsePrompt(prompt: string | undefined) {
    setIsPromptLibraryModalOpen(false);
    if (!prompt) {
      return;
    }
    onInputValueChange(prompt);
  }

  return (
    <div className="border-maia-border flex w-full items-end rounded-md border bg-white shadow-md">
      <div className="flex w-full flex-col items-start px-4 py-3">
        <div className="flex w-full items-end justify-center py-2">
          {textArea}
          {sendButton}
        </div>
        {selectedFileList}
        <Separator />
        <div className="flex w-full justify-between pt-4">
          <div className="flex items-center gap-4">
            {dataButton}
            {promptButton}
          </div>
          <div className="flex items-center justify-end gap-4">
            <Separator orientation="vertical" />
            {modelSelect}
            {/* <Separator orientation="vertical" />*/}
            {/* {helpButton} */}
          </div>
        </div>
      </div>
      <FileSelectorModal
        isOpen={isFileSelectorModalOpen}
        onClose={() => setIsFileSelectorModalOpen(false)}
        fileSelector={fileSelector}
      />
      <PromptLibraryModal
        isOpen={isPromptLibraryModalOpen}
        onClose={onClosePromptLibraryModal}
        onUsePromptClicked={(prompt) => handleUsePrompt(prompt)}
      />
    </div>
  );
}

export default ChatInput;
